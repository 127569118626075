import React from 'react';
import MoreDetails from '../more-details/more-details';
import Summary from '../summary/summary';
import PropertyInfo from '../property-info/property-info';
import Troubleshooting from '../troubleshooting/troubleshooting';
import Availability from '../availability/availability';
import PropertyInfoDetails from '../property-info-2/property-info-2';
import Success from '../summary/success';
import StartPage from '../intro/start-page';
import Intro from '../intro/intro';

export const authRoutes = [
  {
    path: '/property-info',
    element: <PropertyInfo />,
    key: 'property-info',
  },
  {
    path: '/intro',
    element: <StartPage />,
    key: 'after-hours-check',
  },
  // {
  //   path: '/start',
  //   element: <StartPage />,
  //   key: 'intro',
  // },
  {
    path: '/success',
    element: <Success />,
    key: 'success',
  },
  {
    redirect: true,
    path: '/',
    to: '/intro',
    key: 'redirect',
  },
  {
    redirect: true,
    path: '*',
    to: '/intro',
    key: 'redirect',
  },
];

export const portalRoutes = [
  {
    path: '/summary',
    element: <Summary state={{ showAddEditAvailabilityModal: false }} />,
    key: 'summary',
  },
  {
    path: '/more-details',
    element: <MoreDetails />,
    key: 'more-details',
  },
  {
    path: '/property-info-details',
    element: <PropertyInfoDetails />,
    key: 'property-info-details',
  },
  {
    path: '/availability',
    element: <Availability
      state={{ showAddEditAvailabilityModal: false, showEmergencyJobConfirmation: false }}
    />,
    key: 'availability',
  },
  {
    path: '/availability/add',
    element: <Availability state={{ showAddEditAvailabilityModal: true }} />,
    key: 'availability-add',
  },
  {
    path: '/availability/edit',
    element: <Availability state={{ showAddEditAvailabilityModal: true }} />,
    key: 'availability-edit',
  },
  {
    path: '/availability/emergency',
    element: <Availability state={{ showEmergencyJobConfirmation: true }} />,
    key: 'availability-emergency',
  },
  {
    path: '/availability/property-info',
    element: <Availability state={{ showPropertyAccessDetailsModal: true }} />,
    key: 'availability',
  },
  {
    path: '/summary/availability/add',
    element: <Summary state={{ showAddEditAvailabilityModal: true }} />,
    key: 'summary-availability-add',
  },
  {
    path: '/summary/availability/edit',
    element: <Summary state={{ showAddEditAvailabilityModal: true }} />,
    key: 'summary-availability-edit',
  },
  {
    path: '/troubleshooting',
    element: <Troubleshooting state={{ showMoreDetailsModal: false }} />,
    key: 'troubleshooting',
  },
  {
    path: '/troubleshooting/more-details',
    element: <Troubleshooting state={{ showMoreDetailsModal: true }} />,
    key: 'troubleshooting-more-details',
  },
];
