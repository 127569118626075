import React from 'react';

const serviceLogo = (props) => (
  <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.70822 4.82684H30.7909C32.0952 4.82684 33.1624 3.7597 33.1624 2.45541C33.1624 1.15113 32.0952 0.0839844 30.7909 0.0839844H2.70822C1.40393 0.0839844 0.336792 1.15113 0.336792 2.45541C0.336792 3.7597 1.40393 4.82684 2.70822 4.82684Z" fill="white" />
    <path d="M2.70822 17.5578H30.7909C32.0952 17.5578 33.1624 16.4907 33.1624 15.1864C33.1624 13.8821 32.0952 12.8149 30.7909 12.8149H2.70822C1.40393 12.8149 0.336792 13.8821 0.336792 15.1864C0.336792 16.4907 1.40393 17.5578 2.70822 17.5578Z" fill="white" />
    <path d="M18.9338 25.7954H2.70822C1.40393 25.7954 0.336792 26.8626 0.336792 28.1668C0.336792 29.4711 1.40393 30.5383 2.70822 30.5383H18.9338C20.2381 30.5383 21.3052 29.4711 21.3052 28.1668C21.3052 26.8626 20.2381 25.7954 18.9338 25.7954Z" fill="white" />
    <path d="M36.6571 25.7956H34.2856V23.4242C34.2856 22.1199 33.2185 21.0527 31.9142 21.0527C30.6099 21.0527 29.5428 22.1199 29.5428 23.4242V25.7956H27.1714C25.8671 25.7956 24.7999 26.8627 24.7999 28.167C24.7999 29.4713 25.8671 30.5384 27.1714 30.5384H29.5428V32.9099C29.5428 34.2142 30.6099 35.2813 31.9142 35.2813C33.2185 35.2813 34.2856 34.2142 34.2856 32.9099V30.5384H36.6571C37.9614 30.5384 39.0285 29.4713 39.0285 28.167C39.0285 26.8627 37.9614 25.7956 36.6571 25.7956Z" fill="white" />
  </svg>

);
export default serviceLogo;
