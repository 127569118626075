import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  HandyManIcon,
  PlumbingIcon,
  ApplianceIcon,
  HeatingCoolingIcon,
  ElectricalIcon,
  HotWaterHeaterIcon,
  DrainCleaningIcon,
} from '../../assets/images/svg-icons';
import ProgressBar from '../progress-bar/progress-bar';
import MoreDetailsInfoModal from './more-details-info-modal';
import { getServiceCategories } from '../services/services';
import Loader from '../shared/loader';
import { setServiceCategory } from '../reducers/tenant-job-entry';

const Troubleshooting = (props) => {
  const { state: { showMoreDetailsModal } } = props;

  const [serviceCategories, setServiceCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelectJob = (service) => {
    dispatch(setServiceCategory(service));
    navigate('/more-details');
  };

  useEffect(() => {
    setLoading(true);
    getServiceCategories().then((res) => {
      const categories = res?.service_categories;
      const notContainsOther = categories?.filter((item) => item?.slug !== 'other');
      const other = categories?.filter((item) => item?.slug === 'other');
      const sortCategories = [...notContainsOther, ...other];
      setServiceCategories(sortCategories);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  }, []);

  const getIcon = (key) => {
    switch (key) {
      case 'handyman':
        return <HandyManIcon />;
      case 'plumbing':
        return <PlumbingIcon />;
      case 'appliances':
        return <ApplianceIcon />;
      case 'hvac':
        return <HeatingCoolingIcon />;
      case 'electrical':
        return <ElectricalIcon />;
      case 'hot-water-heater':
        return <HotWaterHeaterIcon />;
      case 'drain-cleaning':
        return <DrainCleaningIcon />;
      case 'other':
        return <HandyManIcon />;
      default:
        return <HandyManIcon />;
    }
  };

  return (
    <React.Fragment>
      <ProgressBar id="troubleshoot" />
      <div className="content-wrapper">
        <h2>Choose Service Category</h2>
        <p>What category does your current issue fall under?</p>
        {loading ? <Loader /> : (
          <div className="troubleshoot">
            {!isEmpty(serviceCategories) ? (
              <>
                <div className="service-outer">
                  {serviceCategories?.map((item) => (
                    <Button
                      className="service-card"
                      onClick={() => handleSelectJob(item)}
                      key={item?.service_category_id}
                    >
                      {getIcon(item?.slug)}
                      {item?.label}
                    </Button>
                  ))}
                </div>
                <div className="troubleshoot-details">
                  <p>Not sure about your issue category?</p>
                  <Link to="/troubleshooting/more-details">
                    See More Details
                  </Link>
                </div>
              </>
            ) : 'No Service Category Found'}
          </div>
        )}
      </div>
      <div className="footer-action">
        <div className="container d-flex gap-3">
          <Button
            color="primary"
            block
            onClick={() => navigate('/property-info-details')}
          >
            Back
          </Button>
        </div>
      </div>
      {showMoreDetailsModal ? (
        <MoreDetailsInfoModal
          closeModal={() => navigate('/troubleshooting', { replace: true })}
          serviceCategories={serviceCategories}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default Troubleshooting;
