import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PortalLayout from './components/layout/portal';
import { authRoutes, portalRoutes } from './components/routes/portal';
import { getLocalStorage } from './helpers/utils';
import './scss/style.scss';

function App() {
  const token = getLocalStorage('userAccessToken');
  const tenantDetails = useSelector((state) => state.tenantJobEntry?.tenant_details);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (token) {
        localStorage.removeItem('userAccessToken');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [token]);

  return (
    <Routes>
      <Route element={<PortalLayout />}>
        {portalRoutes.map((prop) => {
          if (prop.redirect) {
            return (
              <Route
                key={prop.key}
                path={prop.path}
                element={<Navigate to={prop.to} key={prop.key} replace />}
              />
            );
          }
          if (token && !isEmpty(tenantDetails)) {
            return (
              <Route
                path={prop.path}
                element={prop.element}
                key={prop.key}
              />
            );
          }
          return (
            <Route
              key={prop.key}
              path={prop.path}
              element={<Navigate to="/property-info" key={prop.key} replace />}
            />
          );
        })}
        {authRoutes.map((prop) => {
          if (prop.redirect) {
            return (
              <Route
                key={prop.key}
                path={prop.path}
                element={<Navigate to={prop.to} key={prop.key} replace />}
              />
            );
          }
          return (
            <Route
              path={prop.path}
              element={prop.element}
              key={prop.key}
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;
