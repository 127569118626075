import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { useSelector } from 'react-redux';

import CustomNumberField from '../shared/form-field/custom-number-field';
import { setLocalStorage } from '../../helpers/utils';
import { phoneNumberVerification, verifyOtp } from '../services/services';
import catchHandler from '../../helpers/catchHandler';
import LoaderRound from '../shared/loader-round';

const OtpVerificationModal = (props) => {
  const { closeModal, setShowPropertyInfoModal } = props;
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [timer, setTimer] = useState('');

  const phoneNumber = useSelector((state) => state.tenantJobEntry.phone);

  const schema = yup.object().shape({
    otp: yup.string().required('This is a required field').min(6, 'Not a valid otp'),
  });

  const methods = useForm({
    defaultValues: {
      otp: '',
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setError, formState: { errors } } = methods;

  const onSubmit = (formData) => {
    const payload = {
      ...(phoneNumber ? { phone: phoneNumber } : {}),
      ...(formData?.otp ? { code: formData?.otp } : {}),
    };
    setVerifyOtpLoading(true);
    verifyOtp(payload).then((res) => {
      if (res?.token) {
        setLocalStorage('userAccessToken', res?.token);
      }
      setVerifyOtpLoading(false);
      closeModal();
      setShowPropertyInfoModal(true);
    }).catch((err) => {
      setVerifyOtpLoading(false);
      catchHandler(err, setError);
    });
  };

  const timeoutRef = useRef(null);
  const nextResendOtpEnableTimeRef = useRef(null);
  const timerRef = useRef(null);

  const resetResendOtpTimer = () => {
    nextResendOtpEnableTimeRef.current = moment().add(1, 'minutes').valueOf();
  };

  const doTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const currentTime = moment().valueOf();
      timerRef.current = moment(nextResendOtpEnableTimeRef.current).diff(moment(currentTime), 'seconds');
      const duration = moment.duration(timerRef.current, 'seconds');
      setTimer(duration?.asSeconds());
      if (timerRef.current > 0) {
        doTimer();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    resetResendOtpTimer();
    doTimer();
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [doTimer]);

  const handleResendVerification = () => {
    const payload = { phone: phoneNumber };
    setResendCodeLoading(true);
    phoneNumberVerification(payload).then(() => {
      resetResendOtpTimer();
      doTimer();
      setResendCodeLoading(false);
    }).catch((err) => {
      setResendCodeLoading(false);
      catchHandler(err, setError);
    });
  };

  useEffect(() => {
    if (errors?.general_errors?.message) {
      toast(errors?.general_errors?.message, { type: 'error' });
    }
  }, [errors?.general_errors?.message]);

  return (
    <Modal isOpen toggle={closeModal} centered className="verify-otp-modal">
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <FormProvider {...methods}>
          <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Verify your Phone Number</h2>
            <p>Please enter the verification code you got on your phone.</p>
            <div className="otp-form-wrapper">
              <CustomNumberField
                name="otp"
                placeholder="_ _ _ _ _ _"
                format="# # # # # #"
                clearError
                type="text"
                className="form-control"
              />
              {resendCodeLoading ? (
                <div className="loader-tenant">
                  <div className="la-square-jelly-box">
                    <div />
                    <div />
                  </div>
                </div>
              ) : (
                <p>
                  Didn’t get the code?
                  <br />
                  {timer === 0 ? <Link to="#" onClick={handleResendVerification}>Resend verification</Link> : <span>Resend verification</span>}
                  {`${timer ? ` code in ${timer} seconds.` : ''}`}
                </p>
              )}
            </div>
            <div className="modal-action">
              <Button type="submit" color="primary" block>
                {verifyOtpLoading ? <LoaderRound /> : 'Confirm'}

              </Button>
              <Button color="secondary" block onClick={closeModal}>Close</Button>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default OtpVerificationModal;
