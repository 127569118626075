import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import { ErrorMessage } from '@hookform/error-message';
import { setPropertyAccessAllOptions, setPropertyAccessDetails } from '../reducers/tenant-job-entry';
import { getPropertiesAccessDetails } from '../services/services';
import CustomRadioButton from '../shared/form-field/custom-radio-button';
import InputField from '../shared/form-field/input-field';
import Loader from '../shared/loader';

const PropertyAccessDetailsModal = ({ closeModal }) => {
  const [propertyAccessOptions, setPropertyAccessOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertyAccess = useSelector((state) => state.tenantJobEntry?.property_access);
  const specificAccessNote = useSelector((state) => state.tenantJobEntry?.specific_accessNote);
  const doorCode = useSelector((state) => state.tenantJobEntry?.door_code);

  const schema = yup.object().shape({
    propertyAccess: yup.string().required('select a property access option'),
    doorCode: yup.string().when('propertyAccess', {
      is: 'digital-key-code',
      then: yup.string().required('This is a required field')
        .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespace'),
    }),
    specificAccessNote: yup.string().when('propertyAccess', {
      is: 'hidden-key',
      then: yup.string().required('This is a required field')
        .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespace'),
    }),
  });

  const buildFormData = useCallback((data) => {
    const formData = {};
    formData.propertyAccess = data?.propertyAccess
      || Object.keys(propertyAccessOptions)[0];
    formData.specificAccessNote = data?.specificAccessNote || '';
    formData.doorCode = data?.doorCode || '';
    return formData;
  }, [propertyAccessOptions]);

  const methods = useForm({
    defaultValues: {
      propertyAccess: '',
      doorCode: '',
      specificAccessNote: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = methods;

  const propertyAccessFormData = useWatch({ control, name: 'propertyAccess' });

  useEffect(() => {
    const defaultValues = {
      propertyAccess: propertyAccess || Object.keys(propertyAccessOptions)[0],
      specificAccessNote,
      doorCode,
    };
    if ((propertyAccess || specificAccessNote) || !isEmpty(propertyAccessOptions)) {
      reset(buildFormData(defaultValues));
    }
  }, [
    buildFormData,
    reset,
    propertyAccessOptions,
    doorCode,
    propertyAccess,
    specificAccessNote,
  ]);

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      specificAccessNote: formData?.specificAccessNote?.trim().length ? formData?.specificAccessNote : '',
    };
    dispatch(setPropertyAccessDetails(payload));
    navigate('/summary');
  };

  useEffect(() => {
    setLoading(true);
    getPropertiesAccessDetails().then((res) => {
      setLoading(false);
      setPropertyAccessOptions(res?.how_to_access_property);
      dispatch(setPropertyAccessAllOptions(res?.how_to_access_property));
    }).catch((err) => {
      setLoading(false);
    });
  }, [setError, dispatch]);

  return (
    <Modal isOpen toggle={closeModal} centered>
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <FormProvider {...methods}>
          <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Property Access Details</h2>
            <p>Please confirm access information for your Lula pro</p>
            {loading ? (
              <Loader />
            ) : (
              <div className="property-access-details-form">
                {Object.keys(propertyAccessOptions)?.map((item) => {
                  const data = propertyAccessOptions[item];
                  return (
                    <React.Fragment key={item}>
                      <FormGroup check>
                        <CustomRadioButton
                          name="propertyAccess"
                          type="radio"
                          value={item}
                          clearError
                          fieldValue={item}
                          hideFormError
                        />
                        {' '}
                        <Label check>
                          <div className="circle">
                            <div className="circle-selected" />
                          </div>
                          <p>{data}</p>
                        </Label>
                      </FormGroup>
                      {propertyAccessFormData === 'digital-key-code'
                      && item === 'digital-key-code' ? (
                        <FormGroup>
                          <InputField
                            id=""
                            name="doorCode"
                            clearError
                            placeholder="Enter Digital Key"
                            type="text"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="propertyAccess"
                            render={({ message }) => (
                              <small className="text-danger">{message}</small>
                            )}
                          />
                        </FormGroup>
                        ) : (
                          ''
                        )}
                    </React.Fragment>
                  );
                })}
                <InputField
                  name="specificAccessNote"
                  type="textarea"
                  clearError
                  className="specific-access-note"
                  placeholder="Specific Access Note"
                />
              </div>
            )}
            <div className="modal-action">
              <Button
                type="submit"
                color="primary"
                block
                disabled={loading}
              >
                Confirm
              </Button>
              <Button
                color="secondary"
                block
                onClick={closeModal}
              >
                Close
              </Button>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default PropertyAccessDetailsModal;
