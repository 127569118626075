import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { ServiceLogoIcon } from '../../assets/images/svg-icons';

const StartPage = () => {
  const navigate = useNavigate();

  return (
    <div className="global-card global-card--intro">
      <div className="main-icon">
        <ServiceLogoIcon />
      </div>
      <h2>Create Service Request</h2>
      <p className="text-center">
        Please submit your work order request by clicking start now. Please
        provide as much information as you can to help us expedite resolution.
      </p>
      <Button
        color="primary"
        className="w-100"
        onClick={() => navigate('/property-info')}
      >
        Start Now
      </Button>
    </div>
  );
};

export default StartPage;
