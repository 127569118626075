/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const tenantJobEntrySlice = createSlice({
  name: 'tenantJobEntry',
  initialState: {
    phone: '',
    tenant_details: '',
    service_category: '',
    description: '',
    job_medias: [],
    is_emergency: false,
    job_schedules: {},
    property_access: '',
    specific_accessNote: '',
    door_code: '',
    property_access_options: {},
    work_order_status: '',
    summaryFormErrors: {},
    lookup_data: {},
    fetching_lookup_data: false,
  },
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phone = action.payload;
    },
    setSelectedTenant: (state, action) => {
      state.tenant_details = action.payload;
    },
    setServiceCategory: (state, action) => {
      state.service_category = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setUploadedImage: (state, action) => {
      const currentJobMedia = [...state.job_medias, action.payload];
      state.job_medias = currentJobMedia;
    },
    setRemoveImage: (state, action) => {
      const newJobMedia = state.job_medias?.filter((item) => item?.job_media_id !== action.payload);
      state.job_medias = newJobMedia;
    },
    setJobEmergency: (state, action) => {
      state.is_emergency = action.payload;
    },
    setAvailabilities: (state, action) => {
      state.job_schedules = action.payload;
    },
    setPropertyAccessDetails: (state, action) => {
      const { propertyAccess, specificAccessNote, doorCode } = action.payload;
      state.property_access = propertyAccess;
      state.specific_accessNote = specificAccessNote;
      state.door_code = doorCode;
    },
    setPropertyAccessAllOptions: (state, action) => {
      state.property_access_options = action.payload;
    },
    setWorkOrderStatus: (state, action) => {
      state.work_order_status = action.payload;
    },
    setSummaryFormErrors: (state, action) => {
      state.summaryFormErrors = action.payload;
    },
    resetFormData: (state, action) => {
      state.phone = '';
      state.tenant_details = '';
      state.service_category = '';
      state.description = '';
      state.job_medias = [];
      state.is_emergency = false;
      state.job_schedules = {};
      state.property_access = '';
      state.specific_accessNote = '';
      state.door_code = '';
      state.property_access_options = {};
      state.summaryFormErrors = {};
    },
    setLookupData: (state, action) => {
      state.lookup_data = action.payload;
    },
    setFetchingLookupData: (state, action) => {
      state.fetching_lookup_data = action.payload;
    },
  },
});

export const {
  setPhoneNumber,
  setSelectedTenant,
  setServiceCategory,
  setDescription,
  setUploadedImage,
  setJobEmergency,
  setRemoveImage,
  setAvailabilities,
  setPropertyAccessDetails,
  setPropertyAccessAllOptions,
  setWorkOrderStatus,
  resetFormData,
  setSummaryFormErrors,
  setLookupData,
  setFetchingLookupData,
} = tenantJobEntrySlice.actions;

export default tenantJobEntrySlice.reducer;
