import React, { useEffect, useState } from 'react';
import { Form, Button } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CloseIcon } from '../../assets/images/svg-icons';
import { getLocalStorage } from '../../helpers/utils';
import ProgressBar from '../progress-bar/progress-bar';
import CustomNumberField from '../shared/form-field/custom-number-field';
import { setPhoneNumber } from '../reducers/tenant-job-entry';
import { phoneNumberVerification } from '../services/services';
import catchHandler from '../../helpers/catchHandler';
import OtpVerificationModal from './otp-verification-modal';
import ConfirmPropertyInfoModal from './confirm-property-info-modal';
import LoaderRound from '../shared/loader-round';

const PropertyInfo = () => {
  const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false);
  const [showPropertyInfoModal, setShowPropertyInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const lookUpData = useSelector((state) => state.tenantJobEntry.lookup_data);

  useEffect(() => {
    const userAccessToken = getLocalStorage('userAccessToken');
    if (userAccessToken) {
      setShowPropertyInfoModal(true);
    }
  }, []);

  const schema = yup.object().shape({
    phone: yup.string().required('This is a required field').min(10, 'Phone number is not valid'),
  });

  const methods = useForm({
    defaultValues: {
      phone: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    resetField,
    setError,
    formState,
  } = methods;
  const { isDirty, errors } = formState;

  const onSubmit = (formData) => {
    const payload = {
      ...(formData.phone ? { phone: formData.phone } : {}),
      customer_id: lookUpData?.customer_id,
    };
    dispatch(setPhoneNumber(payload?.phone));
    setIsLoading(true);
    phoneNumberVerification(payload).then(() => {
      setIsLoading(false);
      localStorage.removeItem('userAccessToken');
      setShowOtpVerificationModal(true);
    }).catch((err) => {
      setIsLoading(false);
      catchHandler(err, setError);
    });
  };

  useEffect(() => {
    if (errors?.general_errors?.message) {
      toast(errors?.general_errors?.message, { type: 'error' });
    }
  }, [errors?.general_errors?.message]);

  return (
    <React.Fragment>
      <ProgressBar id="property-info" />
      <FormProvider {...methods}>
        <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="content-wrapper">
            <h2>Property/Resident Info</h2>
            <p>
              Please enter your phone number to retrieve your property info.
            </p>
            <div className="resident-info-data">
              <div className="input-wrapper">
                <CustomNumberField
                  name="phone"
                  placeholder="(_ _ _) _ _ _ - _ _ _ _"
                  format="(# # #) # # # - # # # #"
                  type="text"
                  clearError
                  className="form-control"
                />
                {isDirty ? (
                  <Link to="" className="close-icon" onClick={() => resetField('phone')}>
                    <CloseIcon />
                  </Link>
                ) : (
                  ''
                )}
              </div>
              {lookUpData?.support_number ? (
                <div className="sub-contet-wrapper text-center">
                  <h3>Not able to verify your info?</h3>
                  <p className="support-line">
                    Call our resident support line:
                  </p>
                  <p><a href={`tel:${lookUpData?.support_number}`}>{lookUpData?.support_number}</a></p>
                </div>
              ) : ''}
            </div>
          </div>
          <div className="footer-action">
            <div className="container d-flex gap-3">
              <Button type="submit" color="primary" block disabled={isLoading}>
                {isLoading ? (
                  <LoaderRound />
                ) : 'Retrieve Information'}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>

      {showOtpVerificationModal ? (
        <OtpVerificationModal
          closeModal={() => setShowOtpVerificationModal(false)}
          setShowPropertyInfoModal={setShowPropertyInfoModal}
        />
      ) : (
        ''
      )}

      {showPropertyInfoModal ? (
        <ConfirmPropertyInfoModal
          closeModal={() => {
            setShowPropertyInfoModal(false);
          }}
        />
      ) : (
        ''
      )}

    </React.Fragment>
  );
};

export default PropertyInfo;
