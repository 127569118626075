import React, {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import * as yup from 'yup';
import { isEmpty, omit } from 'lodash';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import moment from 'moment';
import DatePickerField from '../shared/form-field/date-picker';
import CustomCheckbox from '../shared/form-field/custom-checkbox-field';
import InputField from '../shared/form-field/input-field';

import { setAvailabilities } from '../reducers/tenant-job-entry';
import { timeWindows } from '../config';

const CustomInput = forwardRef(({ onClick, value }, ref) => (
  <input
    onClick={onClick}
    value={value}
    type="text"
    className="date-picker-input"
    placeholder="Click to select Date"
    readOnly
  />
));

const AddEditAvailabilityModal = (props) => {
  const {
    closeModal,
    availability,
    clearError,
  } = props;
  const location = useLocation();
  const id = location?.state?.key;
  const defaultValues = availability[id];
  const dispatch = useDispatch();
  const [scheduleWindowTimings, setScheduleWindowTimings] = useState(timeWindows);
  const [excludeDates, setExcludeDates] = useState([]);

  const schema = yup.object().shape({
    date: yup.string().required('Required').nullable(),
    timings: yup
      .array()
      .when(
        'date',
        (date, field) => (date ? field.min(1, 'Please select atleast one arrival window') : field),
      ),
  });

  const formErrors = useSelector((state) => state.tenantJobEntry?.summaryFormErrors);

  const buildFormData = useCallback((data) => {
    const formData = {};
    const date = data?.date ? new Date(data?.date) : null;
    formData.date = date || '';
    formData.timings = data?.timings || [];
    formData.constraints = data?.constraints || '';
    return formData;
  }, []);

  const methods = useForm({
    defaultValues: buildFormData(defaultValues),
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
  } = methods;

  const { errors } = formState;

  useEffect(() => {
    reset(buildFormData(defaultValues));
  }, [buildFormData, reset, defaultValues]);

  const onSubmit = (formData) => {
    const newAvailability = { ...availability };
    newAvailability[id] = formData;
    dispatch(setAvailabilities(newAvailability));

    if (formErrors?.job_schedules?.dates) {
      clearError();
    }
    closeModal();
  };

  useEffect(() => {
    const excludeScheduleWindows = Object.keys(availability)?.filter(
      (item) => item !== id,
    );
    const newExcludeDates = [];
    if (!isEmpty(excludeScheduleWindows)) {
      excludeScheduleWindows?.forEach((key) => {
        const scheduleWindow = availability[key];
        if (scheduleWindow?.date) {
          const date = moment(scheduleWindow?.date).format('YYYY-MM-DD');
          newExcludeDates.push(date);
        }
      });
    }
    const currentTime = moment().valueOf();
    const finalTiming = moment().startOf('day').add(12, 'hours').valueOf();
    // this is to exclude today date from calender if current time passed last timing
    if (currentTime > finalTiming) {
      const date = moment().format('YYYY-MM-DD');
      newExcludeDates.push(date);
    }
    setExcludeDates(newExcludeDates);
  }, [availability, id]);

  const isExcludeDate = (date) => {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    if (excludeDates?.includes(dateFormat)) {
      return false;
    }
    return true;
  };

  const selectedDate = useWatch({ control, name: 'date' });

  useEffect(() => {
    const isSelectedDateSameAsToday = moment(selectedDate).isSame(moment(), 'day');
    if (isSelectedDateSameAsToday) {
      const currentTime = moment().valueOf();
      const morningStartTime = moment().startOf('day').add(8, 'hours').valueOf();
      const afternoonStartTime = moment().startOf('day').add(12, 'hours').valueOf();
      const eveningStartTime = moment().startOf('day').add(16, 'hours').valueOf();
      if (currentTime < morningStartTime) {
        setScheduleWindowTimings(timeWindows);
      } else if (currentTime < afternoonStartTime) {
        setScheduleWindowTimings((prev) => omit(prev, 'morning', 'anytime'));
      } else if (currentTime < eveningStartTime) {
        setScheduleWindowTimings((prev) => omit(prev, 'morning', 'afternoon', 'anytime'));
      } else {
        setScheduleWindowTimings({});
      }
    } else {
      setScheduleWindowTimings(timeWindows);
    }
  }, [selectedDate]);

  return (
    <Modal isOpen toggle={closeModal} centered>
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <FormProvider {...methods}>
          <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Select Your Availability</h2>
            <div className="data-picker-outer">
              <DatePickerField
                customInput={<CustomInput />}
                name="date"
                dateFormat="EEE MMM dd, yyyy"
                filterDate={(date) => isExcludeDate(date)}
                minDate={new Date()}
                withPortal
                handleOnChange={() => setValue('timings', [])}
              />
            </div>
            <div className="arrival-time-wrapper">
              <h4>Select Arrival Window</h4>
              <p>The more options the better</p>
              {Object.keys(scheduleWindowTimings)?.map((item) => {
                const label = timeWindows[item];
                return (
                  <FormGroup check className="arrival-time" key={item}>
                    <Label check>
                      <CustomCheckbox
                        name="timings"
                        hideFormError
                        disabled={!selectedDate}
                        handleOnchange={(event, value, onChange) => {
                          if (event?.target?.checked) {
                            const newValue = [...value];
                            newValue.push(event?.target?.value);
                            if (newValue.includes('anytime')) {
                              if (!newValue.includes('morning')) {
                                newValue.push('morning');
                              }
                              if (!newValue.includes('afternoon')) {
                                newValue.push('afternoon');
                              }
                              if (!newValue.includes('evening')) {
                                newValue.push('evening');
                              }
                            }
                            if (newValue.includes('morning') && newValue.includes('afternoon') && newValue.includes('evening')) {
                              if (!newValue.includes('anytime')) {
                                newValue.push('anytime');
                              }
                            }
                            onChange(newValue);
                          } else {
                            let newValue = [...value];
                            if (event?.target?.value === 'anytime') {
                              if (newValue.includes('morning')) {
                                newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'morning'));
                              }
                              if (newValue.includes('afternoon')) {
                                newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'afternoon'));
                              }
                              if (newValue.includes('evening')) {
                                newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'evening'));
                              }
                            }
                            if (event?.target?.value === 'morning' || event?.target?.value === 'afternoon' || event?.target?.value === 'evening') {
                              if (newValue.includes('anytime')) {
                                newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'anytime'));
                              }
                            }
                            newValue = newValue?.filter((obj) => (obj !== event?.target?.value));
                            onChange(newValue);
                          }
                        }}
                        fieldValue={item}
                        id="checkbox2"
                        type="checkbox"
                        value={item}
                      />
                      {' '}
                      {label}
                    </Label>
                  </FormGroup>
                );
              })}
              {errors?.timings?.message ? (
                <small className="text-danger">
                  {errors?.timings?.message}
                </small>
              ) : (
                ''
              )}
              <FormGroup className="arrival-time arrival-time-constraints">
                <Label for="">
                  Time Constraints (Optional)
                </Label>
                <InputField
                  className="time-constraints-info"
                  name="constraints"
                  placeholder="Example: Anytime after 9am."
                  type="text"
                />
              </FormGroup>
              <div className="modal-action">
                <Button type="submit" color="primary" block>
                  Confirm
                </Button>
                <Button color="secondary" block onClick={closeModal}>
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddEditAvailabilityModal;
