import axios from 'axios';
import { toast } from 'react-toastify';

const { CancelToken } = axios;
const cancel = {};

// Formate Request Options
export function requestOptions(
  method = 'GET',
  url = null,
  params = null,
  isAuthenticated = true,
  contentType = null,
  cancelToken = null,
) {
  const options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: 'include',
    // withCredentials: true,
    method,
    url: `${process.env.REACT_APP_API_URL}/residents${url}`,
  };
  if (isAuthenticated) {
    const token = localStorage.getItem('userAccessToken');
    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }
  }
  if (method !== 'POST' && method !== 'PUT' && params) {
    options.params = params;
  // } else if (method === 'POST' && params) {
  //   options.params = params;
  } else if (contentType === 'json') {
    options.headers['Content-Type'] = 'application/json';
    options.data = params;
  } else if (contentType === 'form-data') {
    options.headers['Content-Type'] = 'multipart/form-data';
    options.data = params;
  } else {
    options.data = params;
  }
  if (cancelToken) {
    options.cancelToken = new CancelToken(((c) => {
      cancel[cancelToken] = c;
    }));
  }
  return options;
}

// Handle Error  Response
export function handleError(route, error) {
  if (error && error.response) {
    if (error.code === 'ERR_NETWORK') {
      toast('Something went wrong.', { type: 'error' });
    }
    if (error.response.status === 401) {
      localStorage.removeItem('userAccessToken');
      window.location.assign('/intro');
    }
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
}

// Handle Response
export function handleResponse(route, response) {
  if (response && response.data) {
    return response.data;
  }

  return response;
}

// Common Request
export function request(options) {
  if (options.cancelToken && cancel[options.cancelToken]) {
    cancel[options.cancelToken]();
  }
  // axios.defaults.withCredentials = true;
  return axios(
    requestOptions(
      options.method,
      options.url,
      options.param,
      options.isAuthenticated,
      options.contentType,
      options.cancelToken,
    ),
  ).then((response) => handleResponse(options.route, response, options.is_authenticated))
    .catch((error) => {
      if (axios.isCancel(error)) {
        return handleError(options.route, error);
      }
      return handleError(options.route, error);
    });
}
