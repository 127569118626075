import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import ProgressBar from '../progress-bar/progress-bar';

const PropertyInfoDetails = () => {
  const navigate = useNavigate();
  const tenantDetails = useSelector((state) => state.tenantJobEntry.tenant_details);
  return (
    <React.Fragment>
      <ProgressBar id="property-info" />
      <div className="content-wrapper">
        <h2>Property/Resident Info</h2>
        <p>Please confirm this is the property you need to submit a maintenance request for.</p>
        <div className="content-wrapper-scrollable">
          <div className="global-card-sm global-card-sm__properyt-address">
            <div>
              <label>Property Address</label>
              <address>{tenantDetails?.address}</address>
            </div>
            <div className="global-card-sm__properyt-address__map">
              {/* <img src="/assets/images/map.png" alt="Map" /> */}
            </div>
          </div>
          <div className="global-card-sm global-card-sm__customer-details">
            <div className="global-card-sm__customer-details__item">
              <label>Tenant Name</label>
              <p>{tenantDetails?.tenant_name}</p>
            </div>
            <div className="global-card-sm__customer-details__item">
              <label>Email Address</label>
              <p>{tenantDetails?.email || ''}</p>
            </div>
            <div className="global-card-sm__customer-details__item">
              <label>Phone Number</label>
              <p>
                <NumberFormat
                  value={tenantDetails?.phone_number}
                  readOnly
                  format="(###) ###-####"
                  className="custom-phone"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-action">
        <div className="container d-flex gap-3">
          <Button
            color="secondary"
            block
            onClick={() => navigate('/property-info')}
          >
            Back
          </Button>
          <Button
            color="primary"
            block
            onClick={() => navigate('/troubleshooting')}
          >
            Continue
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyInfoDetails;
