import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { setDescription } from '../reducers/tenant-job-entry';
import ProgressBar from '../progress-bar/progress-bar';
import TextAreaField from '../shared/form-field/text-area';
import ImageUploader from '../shared/image-uploader';
import { scrollToError } from '../../helpers/utils';

const MoreDetails = () => {
  const [uploadImages, setUploadImages] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shortDescription = useSelector((state) => state.tenantJobEntry?.description);

  const schema = yup.object().shape({
    shortDescription: yup.string().required('This is a required field')
      .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespace'),
  });

  const methods = useForm({
    defaultValues: {
      shortDescription,
      images: [],
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (formData) => {
    dispatch(setDescription(formData?.shortDescription));
    navigate('/availability');
  };

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  return (
    <React.Fragment>
      <ProgressBar id="more-details" />
      <FormProvider {...methods}>
        <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="content-wrapper">
            <h2>Additional Info</h2>
            <p>Please enter detailed information about your issue below.</p>
            <div className="troubleshoot">
              <div className="global-card">
                <div className="global-card__inner-wrapper">
                  <TextAreaField
                    name="shortDescription"
                    placeholder="Please enter detailed information here."
                    className="form-control__textarea"
                    clearError
                  />
                  <h3 className="mt-3 mb-0">Upload Photos</h3>
                  {/* <small> (these help expedite your request)</small> */}
                  <p className="mt-2">
                    Upload up to 8 photos to help our team better understand the
                    situation. If this is regarding an appliance, hot water
                    heater, or HVAC system please include picture of serial #
                    sticker.
                  </p>
                  <ImageUploader
                    uploadImages={uploadImages}
                    setUploadImages={setUploadImages}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-action">
            <div className="container d-flex gap-3">
              <Button
                color="secondary"
                block
                onClick={() => navigate('/troubleshooting')}
              >
                Back
              </Button>
              <Button
                type="submit"
                color="primary"
                block
                disabled={!isEmpty(uploadImages)}
              >
                Continue
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
    </React.Fragment>
  );
};

export default MoreDetails;
