import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import PropertyAccessDetailsModal from './property-access-details-modal';

import ProgressBar from '../progress-bar/progress-bar';
import { setAvailabilities } from '../reducers/tenant-job-entry';
import AddEditAvailability from './add-edit-availability';

const Availability = (props) => {
  const {
    state: {
      showAddEditAvailabilityModal,
      showEmergencyJobConfirmation,
      showPropertyAccessDetailsModal,
    },
  } = props;
  const [availability, setAvailability] = useState({ avail_1: {}, avail_2: {}, avail_3: {} });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isJobEmergency = useSelector((state) => state.tenantJobEntry?.is_emergency);
  const jobSchedules = useSelector((state) => state.tenantJobEntry?.job_schedules);

  useEffect(() => {
    if (!isEmpty(jobSchedules)) {
      setAvailability(jobSchedules);
    }
  }, [jobSchedules]);

  const handleDisabledState = () => {
    const lengthOfAvailability = Object.keys(availability)?.filter(
      (key) => isEmpty(availability[key]),
    )?.length;
    if (!isJobEmergency) {
      return !!lengthOfAvailability;
    }
    return false;
  };

  return (
    <React.Fragment>
      <ProgressBar id="availability" />
      <div className="content-wrapper">
        <h2>Select Availability</h2>
        <p>
          Please provide up to 3 available dates. You can also let us know if
          this is an emergency.
        </p>
        <div className="availability-selection">
          <AddEditAvailability
            availability={availability}
            isJobEmergency={isJobEmergency}
            showAddEditAvailabilityModal={showAddEditAvailabilityModal}
            currentRoute="/availability"
            onEditGotoRoute="/availability/edit"
            onAddGotoRoute="/availability/add"
            showEmergencyJobConfirmation={showEmergencyJobConfirmation}
          />
        </div>
      </div>
      <div className="footer-action">
        <div className="container d-flex gap-3">
          <Button
            color="secondary"
            block
            onClick={() => navigate('/more-details')}
          >
            Back
          </Button>
          <Button
            color="primary"
            block
            onClick={() => {
              dispatch(setAvailabilities(availability));
              navigate('/availability/property-info');
            }}
            disabled={handleDisabledState()}
          >
            Continue
          </Button>
        </div>
      </div>
      {showPropertyAccessDetailsModal ? (
        <PropertyAccessDetailsModal
          closeModal={() => navigate('/availability', { replace: true })}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default Availability;
