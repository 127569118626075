import React, { useEffect, useState } from 'react';
import { Button, Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import ProgressBar from '../progress-bar/progress-bar';
import { createWorkOrder } from '../services/services';
import catchHandler from '../../helpers/catchHandler';
import LoaderRound from '../shared/loader-round';
import ImageUploader from '../shared/image-uploader';
import AddEditAvailability from '../availability/add-edit-availability';
import { setSummaryFormErrors, setWorkOrderStatus } from '../reducers/tenant-job-entry';

const Summary = (props) => {
  const {
    state: {
      showAddEditAvailabilityModal,
    },
  } = props;
  const [uploadImages, setUploadImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState({ avail_1: {}, avail_2: {}, avail_3: {} });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobEntryDetail = useSelector((state) => state.tenantJobEntry);
  const uploadedImage = useSelector((state) => state.tenantJobEntry?.job_medias);
  const isJobEmergency = useSelector((state) => state.tenantJobEntry?.is_emergency);

  useEffect(() => {
    if (!isEmpty(jobEntryDetail?.job_schedules)) {
      setAvailability(jobEntryDetail?.job_schedules);
    }
  }, [jobEntryDetail?.job_schedules]);

  const methods = useForm({
    defaultValues: {
      images: [],
    },
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const onSubmit = () => {
    setLoading(true);

    const jobSchedules = Object.keys(jobEntryDetail?.job_schedules || {})
      ?.filter((item) => !isEmpty(jobEntryDetail?.job_schedules[item]))
      ?.map((objKey) => {
        const data = jobEntryDetail?.job_schedules[objKey];
        return {
          ...data,
          date: data?.date ? moment(data?.date).format('YYYY-MM-DD') : '',
          timings: data?.timings?.filter((time) => time !== 'anytime'),
        };
      });

    const jobMedias = uploadedImage?.map(
      (item) => item?.job_media_id,
    );
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const payload = {
      is_emergency: jobEntryDetail?.is_emergency ? 'yes' : 'no',
      resident_id: jobEntryDetail?.tenant_details?.resident_id,
      how_to_access: jobEntryDetail?.property_access,
      door_code: jobEntryDetail?.door_code,
      specific_access_notes: jobEntryDetail?.specific_accessNote,
      description: jobEntryDetail?.description,
      service_category: jobEntryDetail?.service_category?.slug,
      zip_code: jobEntryDetail?.tenant_details?.zip_code,
      job_media_ids: jobMedias,
      job_schedules: !jobEntryDetail?.is_emergency ? jobSchedules : [],
      timezone,
    };

    createWorkOrder(payload).then((res) => {
      setLoading(false);
      dispatch(setWorkOrderStatus('success'));
      navigate('/success');
    }).catch((err) => {
      dispatch(setSummaryFormErrors(err?.data?.errors));
      setLoading(false);
      catchHandler(err, setError);
    });
  };

  useEffect(() => {
    if (!isEmpty(errors?.general_errors?.message?.messages)) {
      errors?.general_errors?.message?.messages?.forEach((error) => {
        toast(error, { type: 'error' });
        clearErrors();
      });
    }
    if (!isEmpty(errors?.general_errors?.message?.dates)) {
      document
        ?.getElementById(
          `schedule-date-${errors?.general_errors?.message?.dates[0]}`,
        )
        .scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
    }
  }, [
    errors?.general_errors?.message?.messages,
    errors?.general_errors?.message?.dates,
    clearErrors,
  ]);

  return (
    <React.Fragment>
      <ProgressBar id="summary" />
      <FormProvider {...methods}>
        <Form className="info-form">
          <div className="content-wrapper">
            <h2>Summary</h2>
            <p>Check summary and confirm request.</p>
            <div className="content-wrapper-scrollable content-wrapper-opened">
              <h4>General Information</h4>
              <div className="global-card-sm global-card-sm__properyt-address">
                <div>
                  <label>Property Address</label>
                  <address>{jobEntryDetail?.tenant_details?.address}</address>
                </div>
                <div className="global-card-sm__properyt-address__map">
                  {/* <img src="/assets/images/map.png" alt="Map" /> */}
                </div>
              </div>
              <div className="global-card-sm global-card-sm__customer-details">
                <div className="global-card-sm__customer-details__item">
                  <label>Tenant Name</label>
                  <p>{jobEntryDetail?.tenant_details?.tenant_name}</p>
                </div>
                <div className="global-card-sm__customer-details__item">
                  <label>Category</label>
                  <p>{jobEntryDetail?.service_category?.label}</p>
                </div>
                <div className="global-card-sm__customer-details__item">
                  <label>Description</label>
                  <p>{jobEntryDetail?.description}</p>
                </div>
              </div>
              <h4>Uploaded Photos</h4>
              <div className="global-card">
                <div className="global-card__inner-wrapper">
                  <ImageUploader
                    uploadImages={uploadImages}
                    setUploadImages={setUploadImages}
                  />
                </div>
              </div>
              {!isJobEmergency ? (
                <>
                  <h4>Availability</h4>
                  <AddEditAvailability
                    availability={availability}
                    isJobEmergency={isJobEmergency}
                    showAddEditAvailabilityModal={showAddEditAvailabilityModal}
                    currentRoute="/summary"
                    onEditGotoRoute="/summary/availability/edit"
                    onAddGotoRoute="/summary/availability/add"
                    clearError={clearErrors}
                    hideJobEmergencyCheckBox
                  />
                </>
              ) : (
                ''
              )}
              <h4>Property Access Details</h4>
              <div className="global-card">
                <p>
                  {jobEntryDetail?.property_access
                  && jobEntryDetail?.property_access === 'digital-key-code'
                    ? `Digital Key or Lockbox - ${jobEntryDetail?.door_code}`
                    : ''}
                  {jobEntryDetail?.property_access
                  && jobEntryDetail?.property_access === 'hidden-key'
                    ? 'Hidden key'
                    : ''}
                  {jobEntryDetail?.property_access
                  && jobEntryDetail?.property_access !== 'digital-key-code'
                  && jobEntryDetail?.property_access !== 'hidden-key'
                    ? jobEntryDetail?.property_access_options?.[
                      jobEntryDetail?.property_access
                    ]
                    : ''}
                </p>
              </div>
              {jobEntryDetail?.specific_accessNote ? (
                <div className="global-card global-custom-padding">
                  <p>
                    {jobEntryDetail?.specific_accessNote}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="footer-action">
            <div className="container d-flex gap-3">
              <Button
                color="secondary"
                block
                onClick={() => navigate('/availability')}
              >
                Back
              </Button>
              <Button
                type="submit"
                color="primary"
                block
                onClick={handleSubmit(onSubmit)}
                disabled={!isEmpty(uploadImages) || loading}
              >
                {loading ? <LoaderRound /> : 'Submit'}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
    </React.Fragment>
  );
};

export default Summary;
