import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetFormData } from '../reducers/tenant-job-entry';
import { SubmittedIcon } from '../../assets/images/svg-icons';

const Success = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workOrderStatus = useSelector((state) => state.tenantJobEntry?.work_order_status);

  useEffect(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  useEffect(() => {
    if (workOrderStatus !== 'success') {
      navigate('/property-info');
    }
  }, [workOrderStatus, navigate]);

  return (
    <div className="global-card global-card--intro">
      <div className="mb-4">
        <SubmittedIcon />
      </div>
      <h2 className="text-center">Work Order Request Submitted!</h2>
      <h5>What Happens Now?</h5>
      <p className="text-center">
        Your work order is currently being scheduled with a fully-vetted Lula
        approved pro. You will receive a text message confirming the date and
        time once scheduled.
      </p>
      <Button color="primary" block onClick={() => navigate('/property-info')}>
        Submit Another Work Order
      </Button>
    </div>
  );
};

export default Success;
