import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const EmergencyConfirmationModal = ({ closeModal, handleJobEmergencySwitch }) => (
  <Modal isOpen toggle={closeModal} centered>
    <ModalHeader toggle={closeModal} />
    <ModalBody>
      <h2>Emergency</h2>
      <p>These are examples of what is considered an emergency:</p>
      <ul>
        <li>No Heating or Cooling in Extreme Temperatures</li>
        <li>Active Leak or Flooding of Home</li>
        <li>Plumbing Backup or Clog in Multiple Plumbing Fixtures</li>
        <li>Security or Safety Concerns (i.e. front door can&apos;t be shut)</li>
        <li>Health Hazard </li>
      </ul>
      <div className="modal-action">
        <Button
          color="primary"
          block
          onClick={() => {
            handleJobEmergencySwitch();
            closeModal();
          }}
        >
          I Confirm, My Job is Emergency
        </Button>
        <Button color="secondary" block onClick={closeModal}>
          Close
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

export default EmergencyConfirmationModal;
