import React from 'react';

const ProgressBar = ({ id }) => {
  const ids = ['property-info', 'troubleshoot', 'more-details', 'availability', 'summary'];

  const getClassName = (dataKey) => {
    if (id === 'property-info') {
      if (dataKey === 'property-info') {
        return 'selected';
      }
      if (['property-info']?.includes(dataKey)) {
        return 'completed';
      }
    }

    if (id === 'troubleshoot') {
      if (dataKey === 'troubleshoot') {
        return 'selected';
      }
      if (['property-info', 'troubleshoot']?.includes(dataKey)) {
        return 'completed';
      }
    }

    if (id === 'more-details') {
      if (dataKey === 'more-details') {
        return 'selected';
      }
      if (['property-info', 'troubleshoot', 'more-details']?.includes(dataKey)) {
        return 'completed';
      }
    }

    if (id === 'availability') {
      if (dataKey === 'availability') {
        return 'selected';
      }
      if (['property-info', 'troubleshoot', 'more-details', 'availability']?.includes(dataKey)) {
        return 'completed';
      }
    }

    if (id === 'summary') {
      if (dataKey === 'summary') {
        return 'selected';
      }
      if (['property-info', 'troubleshoot', 'more-details', 'availability', 'summary']?.includes(dataKey)) {
        return 'completed';
      }
    }
    return '';
  };

  return (
    <ul className="steps">
      {ids?.map((item, i) => (
        <React.Fragment key={item}>
          <li className={`${getClassName(item)}`}>
            <div className="dot ">
              <div className="dot--inner" />
            </div>
          </li>
          {!(ids.length - 1 === i) ? <li className="step-divider" /> : '' }
        </React.Fragment>
      ))}
    </ul>
  );
};

export default ProgressBar;
