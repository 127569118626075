import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';

import { CalanderIcon } from '../../assets/images/svg-icons';
import { timeWindows } from '../config';
import AddEditAvailabilityModal from './add-edit-availability-modal';
import EmergencyConfirmationModal from './emergency-confirmation-modal';

import { setJobEmergency } from '../reducers/tenant-job-entry';

const AddEditAvailability = (props) => {
  const {
    availability,
    isJobEmergency,
    showAddEditAvailabilityModal,
    currentRoute,
    onEditGotoRoute,
    onAddGotoRoute,
    clearError,
    showEmergencyJobConfirmation,
    hideJobEmergencyCheckBox,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formErrors = useSelector((state) => state.tenantJobEntry?.summaryFormErrors);

  const isDateValid = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    if (formErrors?.job_schedules?.dates.includes(formattedDate)) {
      return 'error-schedule';
    }
    return '';
  };

  const handleJobEmergencySwitch = () => {
    dispatch(setJobEmergency(!isJobEmergency));
  };

  useEffect(() => {
    if (!isEmpty(formErrors?.job_schedules?.dates)) {
      const scrollElement = document?.getElementById(`schedule-date-${formErrors?.job_schedules?.dates[0]}`);
      if (scrollElement) {
        scrollElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }
  }, [formErrors?.job_schedules?.dates]);

  return (
    <div className={currentRoute === '/summary' ? 'schedule-outer pb-0' : 'schedule-outer'}>
      {!hideJobEmergencyCheckBox ? (
        <div
          className="property-card"
          onClick={() => {
            if (!isJobEmergency) {
              navigate('/availability/emergency');
            } else {
              handleJobEmergencySwitch();
            }
          }}
          role="presentation"
        >
          <Input
            type="radio"
            name="radio1"
            checked={isJobEmergency ?? false}
            readOnly
          />
          <div className="property-card--inner">
            <div className="virtual-checkbox" />
            <div className="property-data">
              <div className="customer-name">My Job is Emergency</div>
              <p>I need this service as soon as possible</p>
            </div>
          </div>
        </div>
      ) : ''}
      { !isJobEmergency ? (
        <React.Fragment>
          {' '}
          {Object.keys(availability)?.map((key) => {
            const data = availability[key];
            return (
              <React.Fragment key={`${key}-list`}>
                {!isEmpty(data) ? (
                  <div className={`schedule-added ${data?.date ? isDateValid(data?.date) : ''}`} id={`${data?.date ? `schedule-date-${moment(data?.date).format('YYYY-MM-DD')}` : ''}`}>
                    <div className="schedule-added-left">
                      <div className="icon">
                        <CalanderIcon />
                      </div>
                      <div className="schedule-added-data">
                        <h5>{data?.date ? moment(data?.date).format('ddd MMM DD, yyyy') : ''}</h5>
                        <ul>
                          {data?.timings?.map((item) => {
                            if (data?.timings?.includes('anytime') && item !== 'anytime') {
                              return (
                                <li key={`${item}-${key}`}>{timeWindows[item]}</li>
                              );
                            }
                            if (!data?.timings?.includes('anytime')) {
                              return (
                                <li key={`${item}-${key}`}>{timeWindows[item]}</li>
                              );
                            }
                            return <li key={`${item}-${key}`} />;
                          })}
                        </ul>
                        <p className="constraints">
                          {data?.constraints}
                        </p>
                      </div>
                    </div>
                    <Button
                      className="edit-schedule"
                      onClick={() => navigate(onEditGotoRoute, { state: { key } })}
                    >
                      Edit
                    </Button>
                  </div>
                ) : ''}
              </React.Fragment>
            );
          })}
          {Object.keys(availability)?.map((key) => {
            const data = availability[key];
            return isEmpty(data) ? (
              <div
                onClick={() => navigate(onAddGotoRoute, { state: { key } })}
                className="add-availability"
                role="presentation"
                key={`${key}-button`}
              >
                Add Availability
              </div>
            ) : '';
          })}
          {showAddEditAvailabilityModal ? (
            <AddEditAvailabilityModal
              availability={availability}
              clearError={clearError}
              closeModal={() => navigate(currentRoute, { replace: true })}
            />
          ) : (
            ''
          )}
        </React.Fragment>
      ) : ''}
      {showEmergencyJobConfirmation ? (
        <EmergencyConfirmationModal
          closeModal={() => navigate('/availability', { replace: true })}
          handleJobEmergencySwitch={handleJobEmergencySwitch}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default AddEditAvailability;
