import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Header from './header';
import { setFetchingLookupData, setLookupData } from '../reducers/tenant-job-entry';
import { getLookUpData } from '../services/services';
import 'react-toastify/dist/ReactToastify.css';

const PortalLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFetchingLookupData(true));
    const isDevLocal = window.location.hostname.includes('localhost');
    const domain = isDevLocal ? 'qld' : window.location.hostname.split('.')[0];
    getLookUpData({ domain })
      .then((res) => {
        dispatch(setLookupData(res));
        dispatch(setFetchingLookupData(false));
      })
      .catch((err) => {
        dispatch(setFetchingLookupData(false));
        toast('Something went wrong.', { type: 'error' });
      });
  }, [dispatch]);

  return (
    <React.Fragment>
      {!window?.location?.ancestorOrigins?.[0] ? <Header /> : ''}
      <div className="data-wrapper">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        theme="colored"
      />
    </React.Fragment>
  );
};

export default PortalLayout;
