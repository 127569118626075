import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { setSelectedTenant, setWorkOrderStatus } from '../reducers/tenant-job-entry';
import { getLocations } from '../services/services';
import catchHandler from '../../helpers/catchHandler';
import Loader from '../shared/loader';
import CustomRadioButton from '../shared/form-field/custom-radio-button';

const ConfirmPropertyInfoModal = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tenantDetails, setTenantDetails] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    locationId: yup.string().required('Required'),
  });

  const methods = useForm({
    defaultValues: {
      locationId: '',
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setError, control } = methods;
  const locationId = useWatch({ control, name: 'locationId' });

  useEffect(() => {
    setIsLoading(true);
    dispatch(setWorkOrderStatus(''));
    getLocations()
      .then((res) => {
        setTenantDetails(res?.tenants);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        catchHandler(err, setError);
      });
  }, [setError, dispatch]);

  const onSubmit = (formData) => {
    const selectedTenantDetails = tenantDetails?.filter(
      (item) => item?.resident_id?.toString() === formData?.locationId,
    )?.[0];
    if (selectedTenantDetails) {
      dispatch(setSelectedTenant(selectedTenantDetails));
      navigate('/property-info-details');
    }
  };

  return (
    <Modal
      isOpen
      toggle={closeModal}
      centered
      className="confirm-property-modal"
    >
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <FormProvider {...methods}>
          <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Confirm Property Info</h2>
            <p>Select the property address you want to submit the job for:</p>
            <div className="property">
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  {!isEmpty(tenantDetails)
                    ? tenantDetails?.map((item) => (
                      <div
                        className="property-card"
                        key={item?.resident_id}
                      >
                        <CustomRadioButton
                          type="radio"
                          name="locationId"
                          value={item?.resident_id}
                          fieldValue={item?.resident_id?.toString()}
                        />
                        <div className="property-card--inner">
                          <div className="virtual-checkbox" />
                          <div className="property-data">
                            <div className="customer-name">
                              {item?.tenant_name}
                            </div>
                            <address>{item?.address}</address>
                          </div>
                        </div>
                      </div>
                    ))
                    : 'No Location Available'}
                </div>
              )}
            </div>
            <div className="modal-action">
              <Button
                type="submit"
                color="primary"
                block
                disabled={!locationId}
              >
                Confirm
              </Button>
              <Button color="secondary" block onClick={closeModal}>
                Close
              </Button>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmPropertyInfoModal;
